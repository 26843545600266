<template>
  <div class="container">
    <el-dialog title="限制区域"
               :close-on-click-modal='false'
               :visible.sync="dialogVisible"
               width="50%">
      <table-page dataName="restricted_areas"
                  ref="table"
                  init
                  :params="paramsObj"
                  :request="fetchLimits">
        <template slot="button">
          <el-button type="primary"
                     @click="$refs.setLimit.showModal(row)">添加限制区域</el-button>
        </template>
        <el-table-column show-overflow-tooltip
                         label="限制区域">
          <template slot-scope="scope">
            <span>{{`${scope.row.province}${scope.row.city || ''}${scope.row.district || ''}${scope.row.net || ''}`}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at"
                         show-overflow-tooltip
                         label="添加时间">
        </el-table-column>
        <el-table-column show-overflow-tooltip
                         label="操作">
          <template slot-scope="scope">
            <el-button type="text"
                       class="mr-15"
                       @click="delLimit(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </table-page>
      <span slot="footer"
            class="dialog-footer">

      </span>
    </el-dialog>
    <SetLimit ref="setLimit"
              @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import SetLimit from './SetLimit'
import { fetchLimits, delLimit } from '@/api/market'
export default {
  data () {
    return {
      fetchLimits,
      dialogVisible: false,
      paramsObj: {},
      row: {}
    }
  },
  components: {
    TablePage,
    SetLimit
  },

  methods: {
    showTable (row) {
      this.dialogVisible = true
      this.paramsObj = {
        activity_id: row.id
      }
      this.row = row
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    delLimit (row) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delLimit({
          activity_id: this.row.id,
          delete_restricted_areas: [{
            province: row.province,
            city: row.city,
            district: row.district,
            net: row.net
          }]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
