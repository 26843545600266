<template>
  <el-dialog title="添加限制区域"
             width="50%"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible">
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-form-item label="手机号码"
                    prop="tel">
        <Area v-model="areaAddress"
              :disabled="disabled"
              @change="areaChange" />
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button type="primary"
                 @click="submitForm()">立即添加</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { setLimit } from '@/api/market'
import Area from '@/components/Area'
export default {
  data () {
    return {
      disabled: null,
      areaAddress: {
        province: '',
        city: '',
        district: '',
        net: ''
      },
      form: {},
      rules: {
        tel: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        p_user_id: [
          { required: true, type: 'number', message: '请选择所属员工号码', trigger: 'change' }
        ]
      },
      dialogVisible: false,
      options: [],
      selectLoading: false,
      activity_id: ''
    }
  },
  components: {
    Area
  },
  methods: {
    areaChange (area) {

    },
    showModal (row) {
      this.dialogVisible = true
      this.areaAddress = {
        province: row.list_province,
        city: row.list_city,
        district: row.list_district,
        net: row.list_net
      }
      this.activity_id = row.id
      if (row.list_net) {
        this.disabled = 3
      } else if (row.list_district) {
        this.disabled = 2
      } else if (row.list_city) {
        this.disabled = 1
      } else if (row.list_province) {
        this.disabled = 0
      }
    },
    async submitForm () {
      const res = await setLimit({ activity_id: this.activity_id, restricted_areas: [this.areaAddress] })
      if (res.meta.code === 0) {
        this.$notify({
          title: '成功',
          message: '添加成功',
          type: 'success'
        })
        this.dialogVisible = false
        this.$emit('on-success')
      } else {
        this.$alert(res.meta.msg, {
          type: 'error',
          content: res.meta.msg
        })
      }
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
